/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.pac-container {
    z-index: 9999 !important;
}

.new-order-modal.loading {
    /* Add the blur effect */
    filter: blur(4px);
    -webkit-filter: blur(4px);
}

.loader-container.loading {
    display: flex;
}

.px-20 {
    padding: 0 20px;
}

.new-order-form h1,
.new-order-form h2,
.new-order-form h3 {
    font-weight: 900 !important;
}

.new-order-form .form-section {
    margin: 0 0 30px 0;
}

.new-order-form .form-section .section-label {
    font-weight: 900 !important;
}

.new-order-form .form-section .pac-target-input,
.new-order-form .form-section .text-input {
    width: 100%;
    border: none;
    border-radius: 5px;
    background-color: #F1F0EA;
    padding: 10px 20px;
}

.new-order-form .form-section .section-group .group-item {
    margin: 20px 0 0 0;
}

.new-order-form .form-section .section-group .group-item.dual-column {
    display: flex;
    justify-content: space-between;
}

.new-order-form .form-section .section-group .group-item.dual-column .item-column {
    width: 47%;
}

.new-order-form .form-section .section-group .group-item.dual-column .item-column.flex-col {
    display: flex;
    justify-content: space-between;
}

.new-order-form .form-section .checkbox-list .checkbox-list-item .checkbox-text {
    margin: 0 10px;
}

.new-order-modal .modal-dialog {
    max-width: 800px !important;
}

.new-order-modal .modal-title {
    color: #8EA03A;
}

.btn-submit {
    border: none;
    background-color: #8EA03A;
    color: white;
    font-weight: 500;
    padding: 5px 10px;
}

.btn-close {
    margin: 0 10px !important;
}