.sendmail-modal.loading {
    /* Add the blur effect */
    filter: blur(4px);
    -webkit-filter: blur(4px);
}

.loader-container.loading {
    display: flex;
}

.px-20 {
    padding: 0 20px;
}