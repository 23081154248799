.vertical-center {
    z-index: 1;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.vertical-center.loading {
    /* Add the blur effect */
    filter: blur(4px);
    -webkit-filter: blur(4px);
}

.login-form .form-section {
    margin: 20px 0;
    text-align: center;
}

.login-form .form-section .section-label {
    font-weight: 900;
}

.login-form .form-section .text-input {
    border: none;
    border-radius: 5px;
    background-color: #F1F0EA;
    padding: 10px 20px;
}

.login-btn {
    background-color: #8EA03B !important;
    border: #8EA03B !important;
}

.error-text {
    font-weight: 900;
    color: red;
}

.logo-container>img {
    width: 100%;
}

.loader-container {
    display: none;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    position: absolute;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
}

.loader-container.loading {
    display: flex;
}