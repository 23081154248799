.m-20 {
    margin: 20px;
}

.btn-style {
    width: 165px;
    margin: 0 !important;
}

.text-center {
    text-align: center;
}

.footer {
    margin: 20px;
}

.loading-container {
    height: 100vh;
    display: flex;
    align-items: center;
    vertical-align: middle;
}

.btn-div {
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 750px) {
    .logo {
        width: 100%;
    }
}