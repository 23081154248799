.update-modal.loading {
    /* Add the blur effect */
    filter: blur(4px);
    -webkit-filter: blur(4px);
}

.loader-container.loading {
    display: flex;
}

.px-20 {
    padding: 0 20px;
}

.update-form .form-section .text-input,
.update-form .form-section .date-picker {
    width: 100%;
    border: none;
    border-radius: 5px;
    background-color: #F1F0EA;
    padding: 10px 20px;
}

.update-form .form-section {
    margin: 0 0 30px 0;
}

.update-form .btn-download {
    width: 100px;
    background-color: #3e73b1;
    color: white;
    font-weight: 500;
    padding: 5px 10px;
    text-align: center;
    cursor: pointer;
}

.update-form .form-section .section-label {
    font-weight: bold;
}

.update-form .form-section .checkbox-list .checkbox-list-item .checkbox-text {
    margin: 0 10px;
}