.mx-20 {
    margin: 0 20px;
}

.detail-container {
    margin: 10px auto 50px auto;
    max-width: 1024px;
    width: 100%;
    padding: 20px 50px;
}

.detail-content {
    border-radius: 15px;
    box-shadow: 10px 10px 41px 4px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 10px 10px 41px 4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 41px 4px rgba(0, 0, 0, 0.75);
    padding: 20px;
}

.text-center {
    text-align: center;
}

.col-block {
    margin: 10px 0;
}

.col-block .col-title {
    font-weight: bold;
}

.col-block .col-desc {
    padding-left: 10px !important;
}

.col-block .col-title,
.col-block .col-desc {
    padding: 15px 0;
}

table {
    width: 100%;
}

table tr {
    border-top: 1px solid #000;
}

table tr:first-child {
    border-top: none;
}

@media only screen and (max-width: 750px) {
    .logo {
        width: 100%;
    }
}

@media only screen and (max-width: 40em) {
    .detail-container {
        margin: 0 auto;
        padding: 20px 10px;
    }
}