.btnSendEmail {
    max-width: 140px;
}

.tableCol {
    cursor: pointer;
}

.fileHolder {
    text-align: center;
}

.bg-green {
    background-color: rgba(23, 146, 23, 0.7);
}

.bg-red {
    background-color: rgba(255, 0, 0, 0.7);
}

.dataTable thead:last-child {
    display: none !important;
}

.dataTable td {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    vertical-align: middle !important;
}

.dataTable .tableCol {
    padding-top: 1.1rem;
    padding-bottom: 1rem;
    padding: 0.75rem;
}

.dataTableScroll {
    overflow-x: scroll;
}

@media screen and (max-width: 1295px) {
    .table {
        width: 1295px !important;
    }
}

@media screen and (max-width: 40em) {
    .responsiveTable tbody tr {
        margin-bottom: 20px;
        border: solid 3px !important;
    }

    .responsiveTable td.pivoted {
        border-bottom: solid 1px !important;
        /* position: relative; */
        padding: 0 !important;
        align-items: center;
    }

    .responsiveTable td.pivoted:last-child {
        border-bottom: 0 !important;
    }

    .responsiveTable td {
        display: flex;
    }

    .responsiveTable td .tdBefore {
        width: 50%;
        padding: 0 10px;
        position: relative;
        left: 0;
    }

    .responsiveTable td .tdAfter {
        width: 50%;
        padding: 10px;
    }

    .tableCol {
        padding: 0;
    }
}